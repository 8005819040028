<template>
  <section>
    <breadcrumb :title="$t('accounting')" :subtitle="$t('balances')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-5 is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
        </div>
        <div class="column is-3">
          <b-field v-if="user.userType === userTypes.MANAGER && agenciesList.length">
            <multiselect
                :placeholder="$t('agency')"
                v-model="agency"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="agenciesList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field v-if="user.userType === userTypes.MANAGER && agentsList.length">
            <multiselect
                :placeholder="$t('agent')"
                v-model="agent"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="agentsList">
            </multiselect>
          </b-field>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
          :paginated="false"
          :per-page="itemsPerPage"
      >

        <b-table-column field="data" :label="$t('date')" v-slot="props">
            <span class="col date">
              {{ formatYYYYMMDD(props.row.date) }}
            </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('balances_cup')" v-slot="props">
          <span class="col cup" :class="{'minus':props.row.balanceCup <0}">
         {{ props.row.balanceCup }}
          </span>
        </b-table-column>

        <b-table-column field="code" :label="$t('balances_usd')" sortable v-slot="props">
       <span class="col usd" :class="{'minus':props.row.balanceUSD <0}">
         {{ props.row.balanceUSD }}
       </span>
        </b-table-column>

        <b-table-column field="code" :label="$t('balances_mlc')" sortable v-slot="props">
       <span class="col mlc" :class="{'minus':props.row.balanceMLC <0}">
         {{ props.row.balanceMLC }}
       </span>
        </b-table-column>
      </b-table>
      <hr class="hr-divider">
      <div class="columns is-flex is-justify-content-space-between">
        <div class="column ">
          <span class="total-balance">{{ $t('total_balance_usd') }}:</span>
          <span class="total-balance ml-2" v-if="totalBalanceUsd">{{ totalBalanceUsd.toFixed(2) }}</span>
        </div>
        <div class="column ">
          <span class="total-balance">{{ $t('total_balance_cup') }}:</span>
          <span class="total-balance ml-2" v-if="totalBalanceCup">{{ totalBalanceCup.toFixed(2) }} </span>
        </div>
        <div class="column ">
          <span class="total-balance">{{ $t('total_balance_mlc') }}:</span>
          <span class="total-balance ml-2" v-if="totalBalanceMlc">{{ totalBalanceMlc.toFixed(2) }}</span>
        </div>
      </div>
      <paginate
          :count="desserts.totalRecords"
          :per_page="desserts.itemsPerPage"
          :current-page="desserts.currentPage"
          :total-pages="desserts.totalPages"
          @first="getLast(true)"
          @previous="getBalances(desserts.currentPage - 1,desserts.itemsPerPage)"
          @next="getBalances(desserts.currentPage + 1,desserts.itemsPerPage)"
          @last="getLast"
          @perPage="getBalances(desserts.currentPage,$event)"
          @search="getBalances($event,desserts.itemsPerPage)"

      />
    </section>
  </section>

</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions, mapGetters} from "vuex";
import {USER_TYPE} from "@/enum/userType";
import Multiselect from "vue-multiselect";

export default {
  name: "HistoryComponent",
  components: {Multiselect, Paginate, Breadcrumb},
  data() {
    return {
      userTypes: USER_TYPE,
      agency: null,
      agent: null,
      totalBalanceUsd: null,
      totalBalanceCup: null,
      totalBalanceMlc: null,
      propsModals: {},
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      desserts: [],
      agenciesList: [],
      agentsList: [],
      idQuery: null,
      isAgency: false,

    }
  },
  methods: {
    ...mapActions({
      fetchBalancesAssignment: 'FETCH_AGENCY_BALANCE_ASSIGNMENTS',
      fetchBalancesAssignmentAgent: 'FETCH_AGENT_BALANCE_ASSIGNMENTS',
      fetchAgency: 'FETCH_AGENCY',
      fetchAgent: 'FETCH_AGENT',

    }),
    selectName({name}) {
      return name
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getBalances(page, this.desserts.itemsPerPage)
    },
    getBalances(current = 1, record = 10, id = null) {
      let data = {
        currentPage: this.desserts.currentPage ? this.desserts.currentPage : current,
        recordsPerPage: this.desserts.itemsPerPage ? this.desserts.itemsPerPage : record,
        id: this.idQuery ? this.idQuery : id,
      };
      return new Promise(resolve => {
        if (this.isAgency) {
          this.fetchBalancesAssignment(data)
              .then(value => {
                this.desserts = value.result
                this.totalBalanceUsd = value.amountUSD || 0
                this.totalBalanceCup = value.amountCUP || 0
                this.totalBalanceMlc = value.amountMLC || 0
                resolve('OK')
              })
        } else {
          this.fetchBalancesAssignmentAgent(data)
              .then(value => {
                this.desserts = value.result
                this.totalBalanceUsd = value.amountUSD || 0
                this.totalBalanceCup = value.amountCUP || 0
                this.totalBalanceMlc = value.amountMLC || 0
                resolve('OK')
              })
        }

      })
    },
    getAgencies(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgency(data).then(value => {
          resolve(value)
        })
      })
    },
    getAgents(current = 1, record = 0, id = null, query = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        query: query
      };
      return new Promise(resolve => {
        this.fetchAgent(data).then(value => {
          resolve(value)
        })
      })
    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
  }
  ,
  watch: {
    agency: function () {
      this.idQuery = this.agency.id
      this.isAgency = true
      this.getBalances().then(() => this.agent = null)
      let query = `&AgencyId=${this.agency.id}`
      this.getAgents(1, 0, null, query)
          .then(value => {
            this.agentsList = value.data
          })
    },
    agent: function () {
      this.idQuery = this.agent.id
      this.isAgency = false
      this.getBalances().then(() => this.agency = null)
    }
  }
  ,
  computed: {
    ...
        mapGetters({
          user: 'GET_USER',
        })
  }
  ,
  created() {
    if (this.user.userType !== USER_TYPE.MANAGER) {
      if (this.user.userType === USER_TYPE.AGENCY) {
        this.isAgency = true
      }
      this.getBalances(1, 0, this.user.id)
    }
    if (this.user.userType === USER_TYPE.MANAGER) {
      this.getAgencies()
          .then(value => {
            this.agenciesList = value.data
          })
    }

  }
  ,
}
</script>

<style scoped lang="scss">
.hr-divider {
  height: 1px;
  background: #AEAEAE;
}

.col {
  font-weight: 900;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;

  &.date {
    font-size: 20px;
    color: #C1C1C1;
  }

  &.cup {
    font-size: 16px;
    color: #666BAA;
  }

  &.minus {
    color: #E00F0F !important;
  }

  &.usd {
    color: #0B2B46;
    font-size: 16px;
  }

}

.total-balance {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}
</style>